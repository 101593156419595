<!-- 结算单创建 -->
<template>
  <Modal v-model="showFeeitemsModal" width="1000" :closable="false">
    <Row class="p-b-5" :gutter="8">
      <i-col span="4">
        <DatePicker placeholder="入账开始结束日期" size="small" v-model="dateRage" type="daterange" split-panels
          style="width:100%" :editable="false" clearable @on-change="onChangeDate"></DatePicker>
      </i-col>

      <i-col span="8">
        <i-input
            v-model="query.keyword"
            size="small"
            clearable
            placeholder="关键字（名称、业务单号等）"
          ></i-input>
      </i-col>

      <i-col span="4">
        <Button
          type="primary"
          size="small"
          icon="ios-search"
          @click="reloadList"
        >
        搜索
        </Button>
      </i-col>
      <i-col span="24" class="m-t-10">
          <div
            v-for="(item, index) in listContentCategory"
            :key="index"
            class="p-l-10 p-1-10 m-b-2"
            @click="changeChecked( item.serialNumber)"
            :class="
              checkContentCategorys.includes(item.serialNumber)
                ? 'workplatform-radio-button-active'
                : 'workplatform-radio-button'
            "
          >
            {{ item.moduleName }}
          </div>
        </i-col>
    </Row>
    <Table
      stripe
      border
      :data="list"
      :height="500"
      :columns="tableColumns"
      @on-selection-change="selectionChange"
    ></Table>

    <Row>
      <i-col span="12">
      </i-col>
      <i-col span="12">
        <div class="paging_style">
          <Page
            size="small"
            :total="total"
            :page-size="query.pageSize"
            show-total
            :current="query.pageNumber"
            @on-change="handlePageChanged"
            show-sizer
            :page-size-opts="[15, 50, 100, 200, 500]"
            @on-page-size-change="handlePageSizeChanged"
          ></Page>
        </div>
      </i-col>
    </Row>
    <div slot="footer">
      <Button type="text" @click="showFeeitemsModal = false">取消</Button>
      <Button type="primary" @click="confirm">确认</Button>
    </div>
  </Modal>
</template>
<script>
import { toMoney, toNumber } from '@/utils/wnumb_own'

import {
  listLifeUnsettledBillDetail, getModuleList, addFeeItem
} from '@/api/statement/lifebill'

export default {
  data () {
    return {
      showFeeitemsModal: false,

      total: 0,
      lastPage: true,

      query: {
        billId: 0,
        pageSize: 15,
        pageNumber: 1
      },

      dateRage: [],

      list: [],

      listContentCategory: [],
      checkContentCategorys: [],
      tableColumns: [],

      currentPageAllItemIds: [],
      chkfeeitemIds: []

    }
  },
  methods: {
    // 变更多选框选择
    changeChecked (value) {
      const that = this
      if (that.checkContentCategorys.includes(value)) {
        const index1 = that.checkContentCategorys.indexOf(value)
        if (index1 > -1) {
          that.checkContentCategorys.splice(index1, 1)
        }
      } else {
        that.checkContentCategorys.push(value)
      }
      that.initColumns()
    },
    initColumns () {
      const that = this
      that.tableColumns = []
      const tableColumnArray = {
        chkSelection: {
          type: 'selection',
          key: 'id',
          width: 60,
          align: 'center'
        },
        feeItemTypeName: { title: '类型', key: 'feeItemTypeName', minWidth: 60, align: 'center' },
        settlementCompanyName: { title: '结算主体', key: 'settlementCompanyName', minWidth: 60, align: 'center' },
        chargeTime: { title: '入账时间', key: 'chargeTime', minWidth: 60, align: 'center' },
        goodNo: { title: '业务单号', key: 'goodNo', minWidth: 60, align: 'center' },
        goodName: { title: '名称(商品、媒体)', key: 'goodName', minWidth: 60, align: 'center' },
        incomeTypeName: { title: '收支类型', key: 'incomeTypeName', minWidth: 60, align: 'center' },
        price: {
          title: '单价(元)',
          key: 'price',
          minWidth: 60,
          align: 'center',
          render (h, { row }) {
            return h(
              'span',
              {
                style: { color: '#44bd32', 'font-weight': 'bold' }
              },
              toMoney(row.price)
            )
          }
        },
        quantity: {
          title: '数量',
          key: 'quantity',
          minWidth: 60,
          align: 'center',
          render (h, { row }) {
            return h('span', {}, toNumber(row.quantity))
          }
        },
        verifyAmount: {
          title: '完成金额(元)',
          key: 'verifyAmount',
          minWidth: 60,
          align: 'center',
          render (h, { row }) {
            return h(
              'span',
              {
                style: { color: '#44bd32', 'font-weight': 'bold' }
              },
              toMoney(row.verifyAmount)
            )
          }
        },
        chargeModeDesc: { title: '计价方式', key: 'chargeModeDesc', minWidth: 60, align: 'center' },
        actualAmount: {
          title: '金额(元)',
          key: 'actualAmount',
          minWidth: 60,
          align: 'center',
          render (h, { row }) {
            return h(
              'span',
              {
                style: { color: '#44bd32', 'font-weight': 'bold' }
              },
              toMoney(row.actualAmount)
            )
          }
        },

        // 订单
        orderStatusName: { title: '订单状态', key: 'orderStatusName', minWidth: 60, align: 'center' },
        orderTypeName: { title: '订单类型', key: 'orderTypeName', minWidth: 60, align: 'center' },
        expressTypeName: { title: '订单配送方式', key: 'expressTypeName', minWidth: 60, align: 'center' },
        orderCreateTime: { title: '订单创建时间', key: 'orderCreateTime', minWidth: 60, align: 'center' },
        orderPayTime: { title: '订单付款时间', key: 'orderPayTime', minWidth: 60, align: 'center' },
        orderSuccessTime: { title: '交易成功时间', key: 'orderSuccessTime', minWidth: 60, align: 'center' },

        // 支付信息
        payment: {
          title: '订单应收金额',
          key: 'payment',
          minWidth: 60,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.payment)
              )
            ])
          }
        },
        realPayment: {
          title: '订单实付金额',
          key: 'realPayment',
          minWidth: 60,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.realPayment)
              )
            ])
          }
        },
        postFee: {
          title: '运费',
          key: 'postFee',
          minWidth: 60,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.postFee)
              )
            ])
          }
        },
        transaction: { title: '支付流水号', key: 'transaction', minWidth: 60, align: 'center' },

        // 核销信息
        verifyStateName: {
          title: '核销状态',
          key: 'verifyStateName',
          minWidth: 60,
          align: 'center'
        },
        verifyTime: {
          title: '核销时间',
          key: 'verifyTime',
          minWidth: 60,
          align: 'center'
        },

        // 商品信息
        itemId: {
          title: '商品id',
          key: 'itemId',
          minWidth: 60,
          align: 'center'
        },
        expressStateName: {
          title: '订单商品状态',
          key: 'expressStateName',
          minWidth: 60,
          align: 'center'
        },
        itemNum: {
          title: '商品数量',
          key: 'itemNum',
          minWidth: 60,
          align: 'center'
        },
        discountPrice: { title: '商品单价', key: 'discountPrice', minWidth: 60, align: 'center' },
        itemActualAmount: { title: '商品实际成交金额', key: 'itemActualAmount', minWidth: 60, align: 'center' },

        // 收货信息
        receiverName: {
          title: '收货人/提货人',
          key: 'receiverName',
          minWidth: 60,
          align: 'center'
        },

        receiverPhone: {
          title: '收货人/提货人手机号',
          key: 'receiverPhone',
          minWidth: 60,
          align: 'center'
        },
        receiverProvince: {
          title: '收货人省份',
          key: 'receiverProvince',
          minWidth: 60,
          align: 'center'
        },
        receiverCity: {
          title: '收货人城市',
          key: 'receiverCity',
          minWidth: 60,
          align: 'center'
        },
        receiverDetailAddress: {
          title: '收货人详细',
          key: 'receiverDetailAddress',
          minWidth: 60,
          align: 'center'
        },

        // 商品退款
        refundFee: {
          title: '商品已退款金额',
          key: 'refundFee',
          minWidth: 60,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.receivedAmount)
              )
            ])
          }
        },
        itemRefundStatus: { title: '商品退款状态', key: 'itemRefundStatus', minWidth: 60, align: 'center' },

        // 合同信息
        contractTypeName: { title: '合同类型', key: 'contractTypeName', minWidth: 60, align: 'center' },
        groundCompanyName: { title: '地推', key: 'groundCompanyName', minWidth: 60, align: 'center' },
        businessName: { title: '商家名称', key: 'businessName', minWidth: 60, align: 'center' },
        productUnit: { title: '产品单位', key: 'productUnit', minWidth: 60, align: 'center' },
        operate: {
          title: '操作',
          key: 'action',
          align: 'center',
          width: 80,
          fixed: 'right',
          render: (h, params) => {
            return h('div', [
              h(
                'a',
                {
                  style: { marginRight: '5px' },
                  on: {
                    click: () => {
                      this.$Modal.confirm({
                        title: '操作确认',
                        content: '<p>是否在当前结算单中添加此明细项？</p>',
                        onOk: () => {
                          this.submitloading = true
                          addFeeItem({ billId: this.query.billId, feeItemIdStr: JSON.stringify([params.row.id]) }).then((res) => {
                            if (res && !res.errcode) {
                              this.$Notice.success({ desc: '操作成功！' })
                              this.$store.commit('set_locallifesettlement_update', new Date())
                            }
                            this.initItemArray()
                            this.submitloading = false
                          })
                        }
                      })
                    }
                  }
                },
                '加入结算单'
              )
            ])
          }
        }
      }

      if (!that.checkContentCategorys.length) {
        that.checkContentCategorys = [1]
      }

      that.tableColumns.push(tableColumnArray.chkSelection)
      that.checkContentCategorys.sort((a, b) => (a - b)).forEach(item => {
        const category = that.listContentCategory.filter(x => parseInt(x.serialNumber) === parseInt(item))[0]
        if (category && category.fieldList.length) {
          category.fieldList.forEach(row => {
            // 验证当前全部列是否和数据库对应
            if (tableColumnArray[row.field]) {
              that.tableColumns.push(tableColumnArray[row.field])
            }
          })
        }
      })
    },
    /**
     * 结算单详情显示字段分类接口
     */
    initModuleList () {
      getModuleList({ }).then(res => {
        if (res && !res.errcode) {
          this.listContentCategory = res

          this.initColumns()
        }
      })
    },

    onChangeDate (date) {
      this.query.startDate = date[0]
      this.query.endDate = date[1]
    },
    showModal (billDetail, billId) {
      this.list = []
      this.query.billId = billId

      this.query.settlementType = billDetail.settlementType
      this.query.settlementCompanyId = billDetail.settlementCompanyId

      this.query.pageNumber = 1
      this.query.keyword = null
      this.query.startDate = null
      this.query.endDate = null

      this.initModuleList()
      this.showFeeitemsModal = true
      if (billDetail) {
        this.initItemArray()
      }
    },
    // 加载所有可结算的明细项
    initItemArray () {
      const that = this

      listLifeUnsettledBillDetail(this.query).then((res) => {
        res.list.map((item) => {
          item._checked = that.chkfeeitemIds.includes(item.feeitemId)
          return item
        })
        that.currentPageAllItemIds = res.list.map((item) => {
          return item.id
        })

        that.list = res.list
        that.total = res.totalRow
        that.lastPage = res.lastPage
      })
    },
    handlePageChanged (page) {
      this.query.pageNumber = page
      this.initItemArray()
    },

    handlePageSizeChanged (pagesize) {
      this.query.pageNumber = 1
      this.query.pageSize = pagesize
      this.initItemArray()
    },
    reloadList () {
      this.query.pageNumber = 1
      this.initItemArray()
    },

    // 选中的结算项变更
    selectionChange (selection) {
      const that = this
      const selectionIds = selection.map((item) => {
        return item.id
      })
      // 循环当前页面所有列
      that.currentPageAllItemIds.forEach((item) => {
        // 列是否是选中状态
        if (selectionIds.includes(item)) {
          // 判断是否已在列表，不在则加入
          if (!that.chkfeeitemIds.includes(item)) {
            that.chkfeeitemIds.push(item)
          }
        } else {
          // 列未被选中
          // 判断是否已在列表，在则移除
          if (that.chkfeeitemIds.includes(item)) {
            that.chkfeeitemIds.splice(that.chkfeeitemIds.indexOf(item), 1)
          }
        }
      })
    },
    // 确定添加结算项
    confirm () {
      const that = this
      if (that.chkfeeitemIds.length) {
        const data = {
          billId: this.query.billId,
          feeItemIdStr: JSON.stringify(that.chkfeeitemIds)
        }
        addFeeItem(data).then((res) => {
          if (res && !res.errcode) {
            that.showFeeitemsModal = false
            this.$store.commit('set_locallifesettlement_update', new Date())
          }
        })
      } else {
        that.$Notice.info({ desc: '请至少选择一个结算项！' })
      }
    }
  },
  computed: {
  }
}
</script>
