import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 查询生活圈的结算帐单
 * @param {Object} data
 */
export function listLifeBill (data) {
  return request({
    url: '/ooh-statement/v1/lifeBill/listLifeBill',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 查询生活圈结算帐单的计费项明细表
 * @param {Object} data
 */
export function listLifeBillDetail (data) {
  return request({
    url: '/ooh-statement/v1/lifeBill/listLifeBillDetail',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取生活圈结算单状态
 * @param {Object} data
 */
export function listLifeBillStatus (data) {
  return request({
    url: '/ooh-statement/v1/lifeBill/listLifeBillStatus',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取生活圈收支类型
 * @param {Object} data
 */
export function listLifeIncomeType (data) {
  return request({
    url: '/ooh-statement/v1/lifeBill/listLifeIncomeType',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取生活圈结算公司列表
 * @param {Object} data
 */
export function listLifeSettlementCompany (data) {
  return request({
    url: '/ooh-statement/v1/lifeBill/listLifeSettlementCompany',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取生活圈费用类型列表
 * @param {Object} data
 */
export function listLifeFeeItemType (data) {
  return request({
    url: '/ooh-statement/v1/lifeBill/listLifeFeeItemType',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 结算单确认结算
 * @param {Object} data
 */
export function confirmBill (data) {
  return request({
    url: '/ooh-statement/v1/lifeBill/confirmBill',
    method: 'post',
    data: qs.stringify(data)
  })
}
/**
 * 获取动态显示的字段
 * @param {Object} data
 */
export function getModuleList (data) {
  return request({
    url: '/ooh-statement/v1/lifeBill/getModuleList',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取账单详情基本信息
 * @param {Object} data
 */
export function getBillDetail (data) {
  return request({
    url: '/ooh-statement/v1/lifeBill/getBillDetail',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 查询结算单的调整项列表
 * @param {Object} data
 */
export function listAdjustFeeItem (data) {
  return request({
    url: '/ooh-statement/v1/lifeBill/listAdjustFeeItem',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 结算账单新增调整项
 * @param {Object} data
 */
export function addAdjustFeeItem (data) {
  return request({
    url: '/ooh-statement/v1/lifeBill/addAdjustFeeItem',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 移除结算单中的调整项
 * @param {Object} data
 */
export function removeAdjustFeeItem (data) {
  return request({
    url: '/ooh-statement/v1/lifeBill/removeAdjustFeeItem',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 把计费项明细项添加到结算单中
 * @param {Object} data
 */
export function addFeeItem (data) {
  return request({
    url: '/ooh-statement/v1/lifeBill/addFeeItem',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 移除结算单中的计费项明细
 * @param {Object} data
 */
export function removeFeeItem (data) {
  return request({
    url: '/ooh-statement/v1/lifeBill/removeFeeItem',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 查询生活圈还未结算的计费项明细表
 * @param {Object} data
 */
export function listLifeUnsettledBillDetail (data) {
  return request({
    url: '/ooh-statement/v1/lifeBill/listLifeUnsettledBillDetail',
    method: 'post',
    data: qs.stringify(data)
  })
}
